import logErr, { logMsg } from './err'

export const isMobileDevice =
  typeof window !== 'undefined' && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

export const isAndroid = typeof window !== 'undefined' && /Android/i.test(navigator.userAgent)

export const canShare = process.browser && window && window.navigator && window.navigator.share
/**
 * Wraps share functionality, either by using native iOS, Android share or falling back to copying URL to system clipboard
 *
 * @param {Object} payload
 * @param {string} payload.text  the actual text
 * @param {string} payload.url  a valid url
 * @returns {Promise} either returns "shared", "cancelled" (all good) or Error
 */
const shareIt = ({ text, url }) => {
  if (canShare) {
    // mobile share
    return window.navigator
      .share({ text: text + ' ' + url })
      .then(() => Promise.resolve('shared'))
      .catch((e) => {
        if (e.name === 'AbortError') return Promise.resolve('cancelled')
        else {
          logErr(e, 'share invite link failed')
          return Promise.resolve('error')
        }
      })
  } else {
    logMsg('share tray unsupported')
    // desktop share, i.e copy to clipboard
    return navigator.clipboard
      .writeText(url)
      .then(() => {
        alert(`Your link has been copied to the clipboard \n\n ${url}`)
        return Promise.resolve('shared')
      })
      .catch((e) => {
        logErr(e, 'failed to save link to clipboard')
        return Promise.resolve('error')
      })
  }
}

export default shareIt
