import React from 'react'
import Layout from './Layout'

export default function FlashScreen() {
  return (
    <Layout fullScreen>
      <div id="flash-screen">
        <h2 className="lg">Landslide 2020</h2>
      </div>
    </Layout>
  )
}
