import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import * as Sentry from '@sentry/node'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error) {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.captureException(error)
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Layout title="Error">
          <div id="error-page">
            <h2 className="lg error text-centered">Error</h2>
            <p className="lg">Something went wrong. We're on it!</p>
          </div>
        </Layout>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default ErrorBoundary
