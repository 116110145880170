import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

function ErrorDialog({ title, message, buttons }) {
  return (
    <Modal.Dialog>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
        <p>{process.env.CURRENT_VERSION}</p>
      </Modal.Body>
      <Modal.Footer>
        {buttons &&
          buttons.map((option, index) => {
            const { text, click } = option
            return (
              <Button key={index} onClick={click}>
                {text}
              </Button>
            )
          })}
      </Modal.Footer>
    </Modal.Dialog>
  )
}

ErrorDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object),
}

export default ErrorDialog
