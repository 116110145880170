import React from 'react'

export default function ScreenOrientation() {
  return (
    <div id="screen-orientation">
      <div>
        <h1>Landslide</h1>
        <p>Landslide works best when you turn your phone the other way</p>
      </div>
    </div>
  )
}
