import React from 'react'
import PropTypes from 'prop-types'
import Layout from './Layout'

function WarningScreen({ children, setError }) {
  return (
    <Layout>
      <div id="warning-screen">
        <div>
          {children}
          <button onClick={() => setError('')} className="btn btn-primary btn-block">
            Return to Landslide
          </button>
        </div>
      </div>
    </Layout>
  )
}

WarningScreen.propTypes = {
  setError: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default WarningScreen
